import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const error = next.queryParams['error'];
    const errorDescription = next.queryParams['error_description'];
    const stateParam = next.queryParams['state'];

    return this.auth.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (error && errorDescription && stateParam && !isAuthenticated) {
          this.router.navigate(['/error'], {
            queryParams: {
              error,
              error_description: errorDescription,
              state: stateParam,
            },
          });
          return false;
        }
        return true;
      })
    );
  }
}
