import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(size: number | string, extension: string = ' kb') {
    if (typeof size === 'string') {
      return size.toLowerCase();
    }
    return (size / 1024).toFixed(2) + extension;
  }
}
