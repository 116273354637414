import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { I18nService } from '../../services/i18n/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() displaySideNavBar!: boolean | null;
  @Input() isAgilityConnectExpanded: boolean = false;
  items: MenuItem[] = [];
  isSubTenant!: boolean;
  isTenant!: boolean;
  subscription: Subscription = new Subscription();
  isSuperUser!: boolean;
  constructor(
    private i18nService: I18nService,
    public translate: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    let i18nServiceSubscription = this.i18nService
      .getLanguage()
      .subscribe((language: string) => {
        this.translate.use(language);
      });
    this.subscription.add(i18nServiceSubscription);
    this.isSubTenant = this.userService.isSubTenant;
    this.isTenant = this.userService.isTenant;
    this.isSuperUser = this.userService.isSuperTenant;
    this.items = [
      {
        label: 'General',
        routerLink: '/secure/admin/general-setup',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Tenants',
        routerLink: '/secure/admin/tenant-summary',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Cleanup',
        routerLink: '/secure/admin/cleanup-tenant',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Info',
        routerLink: '/secure/admin/info-summary',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Batch Status',
        routerLink: '/secure/admin/batch-status',
      },
      {
        label: 'Batch Trigger',
        routerLink: '/secure/admin/batch-trigger',
      },
      {
        label: 'Originators',
        routerLink: '/secure/admin/originators-summary',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Index Setup',
        routerLink: '/secure/admin/index-setup',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Financing Sources',
        routerLink: '/secure/admin/financing-sources',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Investors',
        routerLink: '/secure/admin/investor-summary',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Wire Recipients',
        routerLink: '/secure/admin/wire-recipient-summary',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Templates',
        routerLink: `/secure/admin/templates`,
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Conditions',
        routerLink: '/secure/admin/conditions',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Agility Connect',
        expanded: this.isAgilityConnectExpanded,
        items: [
          {
            label: 'Shipping',
            routerLink: '/secure/admin/shipping',
            routerLinkActiveOptions: { exact: true },
          },
          {
            label: 'Wire Recipient Verification',
            routerLink: '/secure/admin/wire-recipient-verification',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
    ];
    if (this.isSuperUser) {
      this.items = this.items.filter((item) => {
        return (
          item.label === 'Tenants' ||
          item.label === 'General' ||
          item.label === 'Cleanup' ||
          item.label === 'Info' ||
          item.label === 'Batch Status' ||
          item.label === 'Batch Trigger'
        );
      });
    }
    if (this.isSubTenant) {
      this.items = this.items.filter((item) => item.label === 'General');
    }
    if (this.isTenant) {
      this.items = this.items.filter(
        (item) =>
          item.label !== 'Tenants' &&
          item.label !== 'Cleanup' &&
          item.label !== 'Info' &&
          item.label !== 'Batch Trigger' &&
          item.label !== 'Batch Status'
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
