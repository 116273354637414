import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UrlParamsService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  hasErrorParams(): boolean {
    const searchParams = new URLSearchParams(this.document.location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');
    const state = searchParams.get('state');
    return !!(error && errorDescription && state);
  }
}
