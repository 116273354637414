export enum CustomEventTokens {
  AGILITY_USER = 'agilityUser',
  MOBILE_NAV_ITEM_CLICKED = 'MOBILE_NAV_ITEM_CLICKED',
  ENGLISH_LANGUAGE = 'en',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  SAVE_LOAN = 'SAVE_LOAN',
  FETCH_LOANS = 'FETCH_LOANS',
  SAVE_SHIPPING_INSTRUCTIONS = 'SAVE_SHIPPING_INSTRUCTIONS',
  SAVE_COLLATERAL_STATUS = 'SAVE_COLLATERAL_STATUS',
  SAVE_ADDITIONAL_WIRE = 'SAVE_ADDITIONAL_WIRE',
  CREATE_ADDITIONAL_WIRE = 'CREATE_ADDITIONAL_WIRE',
  REFRESH_ADDITIONAL_WIRES = 'REFRESH_ADDITIONAL_WIRES',
  CREATE_ADDITIONAL_DRAW = 'CREATE_ADDITIONAL_DRAW',
  LOAN_STATUS_CHANGED = 'LOAN_STATUS_CHANGED',
  FUND_LOAN = 'FUND_LOAN',
  FUND_ADDITIONAL_WIRE = 'FUND_ADDITIONAL_WIRE',
  SUBMIT_LOAN = 'SUBMIT_LOAN',
  EDIT_LOAN = 'EDIT_LOAN',
  CANCEL_LOAN = 'CANCEL_LOAN',
  CREATE_IMB_PROFILE_SUCCESS = 'CREATE_ORIGINATOR_PROFILE_SUCCESS',
  CREATE_TENANT_SUCCESS = 'CREATE_TENANT_SUCCESS',
  BACK = 'BACK',
  NEXT = 'NEXT',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL',
  SAVE_INVESTOR = 'SAVE_INVESTOR',
  CANCEL_INVESTOR = 'CANCEL_INVESTOR',
  SAVE_USER_ROLES_PERMISSIONS = 'GENERAL_USER_ROLES_PERMISSIONS',
  CANCEL_USER_ROLES_PERMISSIONS = 'CANCEL_USER_ROLES_PERMISSIONS',
  IMB_DETAILS = 'ORIGINATOR_DETAILS',
  NEW_IMB = 'NEW_IMB',
  IMB_INVESTORS = 'ORIGINATOR_INVESTORS',
  IMB_CLOSING_AGENTS = 'ORIGINATOR_CLOSING_AGENTS',
  ACTIVITY_LOG = 'ACTIVITY_LOG',
  CREATE_TENANT = 'CREATE_TENANT',
  UPDATE_ROLES = 'UPDATE_ROLES',
  CANCEL_TENANT = 'CANCEL_TENANT',
  CANCEL_IMB = 'CANCEL_ORIGINATOR',
  SAVE_SHIPPING = 'SAVE_SHIPPING',
  CANCEL_SHIPPING = 'CANCEL_SHIPPING',
  CONDITIONS_TAB_CHANGED = 'CONDITIONS_TAB_CHANGED',
  CHANGE_LOAN_TAB = 'CHANGE_LOAN_TAB',
  SAVE_CLOSING_AGENT_VALIDATION = 'SAVE_CLOSING_AGENT_VALIDATION',
  CANCEL_CLOSING_AGENT_VALIDATION = 'CANCEL_CLOSING_AGENT_VALIDATION',
  LOAN_REVERSE_SETTLEMENT = 'LOAN_REVERSE_SETTLEMENT',
  SAVE_ADDITIONAL_DRAW = 'SAVE_ADDITIONAL_DRAW',
  UPDATE_MESSAGE_BANNER = 'UPDATE_MESSAGE_BANNER',
  CREATE_SYSTEM_MESSAGE = 'CREATE_SYSTEM_MESSAGE',
  ADD_OR_UPDATE_DATA_MAPPING = 'ADD_OR_UPDATE_DATA_MAPPING',
  ON_COMMENT_ADDED = 'ON_COMMENT_ADDED',
  LEDGER_DATA = 'showLedgerDetails',
  CURTAILMENT_SELECTION = 'CURAAILMENT_SELECTION',
  CURTAILMENT_LOAN_DATA_FROM_API = 'CURTAILMENT_LOAN_DATA_FROM_API',
  ON_COLLECT = 'ON_COLLECT',
}
