import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from '../../services/loader/loader.service';
import { ErrorMessagesToIgnore } from '../../models/error-messages-to-ignore';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  errorMessage?: string;
  path?: string;
  constructor(private loaderService: LoaderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `${error.error.message}`;
          return throwError(() => new Error(errorMsg));
        } else {
          return throwError(() => new Error(error.message));
        }
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const response = event.body;
          const errorExists = response?.errors;
          if (errorExists && errorExists?.length > 0) {
            const correlationId = `${errorExists[0].extensions['correlationId']}`;
            if (
              errorExists[0]?.extensions['errorDetail'] &&
              errorExists[0]?.extensions['correlationId']
            ) {
              this.errorMessage = `<b>Message:</b> ${errorExists[0].extensions['errorDetail']} </br> <b>CorrelationId:</b> ${errorExists[0].extensions['correlationId']}`;
              this.path = errorExists[0].extensions['path'] as string;
            } else if (
              errorExists[0]?.extensions['errorDetail'] &&
              !errorExists[0]?.extensions['correlationId']
            ) {
              this.errorMessage = `<b>Message:</b> ${errorExists[0].extensions['errorDetail']}`;
              this.path = errorExists[0].extensions['path'] as string;
            } else {
              this.errorMessage = `<b>Message:</b> ${errorExists[0]['message']}`;
              this.path = errorExists[0]['path'] as string;
            }

            // Only throw loader service error if error message is not one we expect
            // and want to handle differently.
            const shouldError =
              Object.keys(ErrorMessagesToIgnore).filter((message) =>
                this.errorMessage?.includes(message)
              ).length == 0;

            if (shouldError) {
              this.loaderService.error(
                this.path,
                this.errorMessage,
                correlationId
              );
            }
          }
        }
        return event;
      })
    );
  }
}
