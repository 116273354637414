import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LabelValue } from '../../models/label-value.interface';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private selectedIMBs: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  setSelectedIMBs(imbs: Array<LabelValue>) {
    this.selectedIMBs.next(imbs);
  }
  getSelectedIMBs(): BehaviorSubject<LabelValue[]> {
    return this.selectedIMBs;
  }
}
