import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  title = 'Error';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      const error = params['error'];
      const errorDescription = params['error_description'];

      if (error === 'unauthorized' && errorDescription === 'user is blocked') {
        this.title = 'Blocked';
      } else {
        this.title = errorDescription || 'Unauthorized';
      }
    });
  }
}
