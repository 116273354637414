<div
  class="container flex flex-column align-items-center justify-content-center bg-white max-w-25rem border-round"
>
  <span class="pi pi-exclamation-triangle color-danger text-7xl"></span>
  <h1>{{ title }}</h1>
  <p class="text-center">You are currently unable to access this account.</p>
  <p class="text-center">
    Please contact your organization's system administrator to determine the
    next steps to reinstate access.
  </p>
  <br />
  <a
    [routerLink]="['/']"
    class="font-bold text-base cursor-pointer hover:underline"
    >Return to Sign In</a
  >
</div>
